/**
 * エリア
 */
import { isBlank } from '@/utilities/typing'
import AreaLevel from '@/models/enums/area-level'

class Area {
    area_id;
    area_name;
    area_level;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.area_id = properties.area_id;
        this.area_name = properties.area_name;
        this.area_level = properties.area_level;
    }

    get area_level_display() {
        return AreaLevel.get(this.area_level);
    }

    get area_level_label() {
        return AreaLevel.get(this.area_level);
    }
}

export default Area;
