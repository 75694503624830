import Enum from './enum'

/**
 * エリア重要度
 */
class AreaLevel extends Enum {
    static PRIMARY = 1; //★最重要エリア
    static VALUED = 2; //☆重要エリア
    static DISTANT = 3; //◆遠方エリア
    static OTHER = 9; //その他

    static values() {
        return {
            [this.PRIMARY]: '★最重要エリア',
            [this.VALUED]: '☆重要エリア',
            [this.DISTANT]: '◆遠方エリア',
            [this.OTHER]: 'その他',
        }
    }
}

export default AreaLevel;
