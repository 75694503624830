<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> マスタ管理 - エリア</h1>
    </header>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <div v-else class="col-sm-36 col-md-28 col-lg-24">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-1"></th>
                        <th class="text-center col-8">エリア</th>
                        <th class="text-center col-8">重要度</th>
                        <th class="text-center col-5"></th>
                    </tr>
                </thead>
                <template v-if="areas.length">
                    <draggable
                            v-model="areas"
                            item-key="area_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle">
                                    <form-input v-model="element.area_name"/>
                                </td>
                                <td class="align-middle">
                                    <form-select
                                        v-model="element.area_level"
                                        :options="AreaLevel.options()"
                                        empty_option="-- 選択 --"
                                    ></form-select>
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input v-model="area_to_create.area_name" /></td>
                        <td class="align-middle">
                            <form-select
                                v-model="area_to_create.area_level"
                                :options="AreaLevel.options()"
                                empty_option="-- 選択 --"
                            ></form-select>
                        </td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import Area from '@/models/entities/area';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import AreaLevel from '@/models/enums/area-level';

export default {
    name: 'MasterArea',
    components: {
        draggable,
        InlineLoader,
        FormInput,
        FormSelect,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // エリア重要度
            AreaLevel,
            // ローディング
            loading: false,
            // 表示データ
            areas: [],
            // 新規
            area_to_create: new Area(),
            // 削除
            area_to_remove: null,
        }
    },
    mounted() {
        this.fetchAreas();
    },
    methods: {
        // 一覧取得
        fetchAreas() {
            //表示データ初期化
            this.areas.splice(0);
            //ローディング開始
            this.loading = true;
            this.$http.get('/master/area')
            .then(response => {
                for (let row of response.data) {
                    this.areas.push(new Area(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading = false;
            });
        },
        // 新規登録
        create() {
            // バリデーション
            if (this.$helper.isBlank(this.area_to_create.area_name)) {
                this.showErrorMessage('エリア名を入力してください');
                return;
            }
            if (this.$helper.isBlank(this.area_to_create.area_level)) {
                this.showErrorMessage('重要度を選択してください');
                return;
            }

            this.startScreenLoading();

            this.$http.post('/master/area', this.area_to_create)
            .then(response => {
                this.areas.push(new Area(response.data));
                this.area_to_create = new Area();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 保存（編集）
        update(area) {
            // バリデーション
            if (this.$helper.isBlank(area.area_name)) {
                this.showErrorMessage('エリア名を入力してください');
                return;
            }
            if (this.$helper.isBlank(area.area_level)) {
                this.showErrorMessage('重要度を選択してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/area/${area.area_id}`, area)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 削除モーダル
        removeReady(area) {
            this.area_to_remove = area;
            this.$refs.confirmRemove.show();
        },
        // 削除処理
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/area/${this.area_to_remove.area_id}`)
            .then(() => {
                const remove_index = this.areas.findIndex((row) => row.area_id === this.area_to_remove.area_id);
                this.areas.splice(remove_index, 1);
                this.area_to_remove = null;
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 並び順更新
        sort() {
            this.startScreenLoading();

            const areas = this.areas.map(area_object => area_object.area_id);

            this.$http.post('/master/area/sort', {
                areas: areas,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
